import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Navbar = ({ siteTitle }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  return (
    <nav>
      <div
        className={`${
          isExpanded
            ? `hidden invisible`
            : `flew-grow bg-pink font-light text-white z-10 p-2 text-center`
        }`}
      >
        İşinizi tio ile büyütmek ister misiniz?
        <Link
          onClick={e => {
            //e.preventDefault()
            trackCustomEvent({
              category: "website",
              action: "click",
              label: "topbar",
            })
          }}
          to="/tr/meet-tio"
          className="underline z-20 relative"
        >
          {" "}
          Hemen tanışalım!{" "}
        </Link>{" "}
        👋
      </div>
      <div
        className={`${
          isExpanded ? `bg-blue` : ``
        } flex items-center justify-between flex-wrap lg:p-6 lg:px-10`}
      >
        <div className="flex items-center flex-shrink-0 text-white mr-6 p-6 lg:p-0 relative z-20 ">
          <Link
            className={`${isExpanded ? `block` : `hidden invisible`}`}
            to="/tr"
          >
            <StaticImage
              src="../../images/logo-white.png"
              width={90}
              formats={["auto", "webp", "avif"]}
              alt={siteTitle}
            />
          </Link>
          <Link
            className={`${isExpanded ? `hidden invisible` : `block`}`}
            to="/tr"
          >
            <StaticImage
              src="../../images/logo-blue.png"
              width={90}
              formats={["auto", "webp", "avif"]}
              alt={siteTitle}
            />
          </Link>
        </div>
        <div className="block  lg:hidden p-6 lg:p-0 ">
          <button
            onKeyDown={() => {
              toggleExpansion(!isExpanded)
            }}
            onClick={() => {
              toggleExpansion(!isExpanded)
            }}
            className={`${isExpanded ? `open` : ``} relative z-20  `}
            id="nav-icon"
          >
            <span className="bg-blue"></span>
            <span className="bg-blue"></span>
            <span className="bg-blue"></span>
          </button>
        </div>
        <div
          className={`${
            isExpanded
              ? `block bg-blue absolute top-0 h-screen text-2xl items-center justify-center`
              : `hidden`
          }  flex lg:top-0 lg:relative w-full z-10 lg:flex lg:items-center lg:w-auto`}
        >
          <div className=" lg:flex-grow ">
            <Link
              to="/tr/about"
              className="py-2 yellow-hover max-w-max mx-auto block mt-4 text-center text-white lg:text-blue  font-thin lg:inline-block lg:mt-0 lg:mr-10  "
            >
              Hakkımızda
            </Link>
            <Link
              to="/tr/our-products"
              className="py-2 yellow-hover max-w-max mx-auto block  mt-4 text-center text-white lg:text-blue  font-thin lg:inline-block lg:mt-0 lg:mr-10  "
            >
              Ürünler
            </Link>
            <Link
              to="/tr/services"
              className="py-2 yellow-hover max-w-max mx-auto block mt-4 text-center text-white lg:text-blue  font-thin lg:inline-block lg:mt-0 lg:mr-10  "
            >
              Hizmetler
            </Link>
            <Link
              to="/tr/resources"
              className="py-2 yellow-hover max-w-max mx-auto block mt-4 text-center text-white lg:text-blue  font-thin lg:inline-block lg:mt-0 lg:mr-10  "
            >
              Kaynaklar
            </Link>
            <Link
              to="/tr/blog"
              className="py-2 yellow-hover max-w-max mx-auto block mt-4 text-center text-white lg:text-blue  font-thin lg:inline-block lg:mt-0 lg:mr-10  "
            >
              Blog
            </Link>
            <Link
              to="/tr/contact"
              className="py-2 yellow-hover max-w-max mx-auto block mt-4 text-center text-white lg:text-blue  font-thin lg:inline-block lg:mt-0 lg:mr-10  "
            >
              İletişim
            </Link>
            <Link
              to="/"
              className="py-2 yellow-hover max-w-max mx-auto block mt-4 text-center text-white lg:text-blue  font-thin lg:inline-block lg:mt-0 lg:mr-10  "
            >
              EN
            </Link>
            <Link
              to="/tr/digital-consultancy"
              className="max-w-max block w-max mx-auto mt-4 text-center lg:inline-block lg:mt-0 px-4 py-2 leading-none border font-thin rounded text-white bg-blue border-blue hover:border-blue hover:text-blue hover:bg-white"
            >
              Demo Talebi Oluştur
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
