/* eslint no-console:0 no-alert:0 */
import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

const Subscribe = () => {
  const [email, setEmail] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(null)

  const handleChange = e => {
    setEmail(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(email)
      .then(({ msg, result }) => {
        if (result !== "success") {
          throw msg
        }
        setIsSubmitted(true)
      })
      .catch(err => {
        // alert(err)
        setIsSubmitted(false)
        setEmail("")
      })
  }

  return (
    <>
      {isSubmitted === null ? (
        <form
          className="flex border-b-2 border-yellow py-4 lg:w-3/4"
          onSubmit={handleSubmit}
        >
          <label htmlFor="email" hidden>
            email
          </label>
          <input
            id="email"
            className="block bg-white outline-none focus:border-yellow mx-auto my-1 max-w-sm w-full font-thin"
            required
            onChange={handleChange}
            type="email"
            name="email"
            placeholder="Email"
            value={email}
          />
          <input
            className="font-bold bg-white cursor-pointer"
            type="submit"
            value="Katıl"
          />
        </form>
      ) : isSubmitted ? (
        <form className="flex border-b-2 border-yellow py-4 lg:w-3/4">
          <label htmlFor="success" hidden>
            success
          </label>
          <input
            id="success"
            type="text"
            className="block bg-white outline-none focus:border-yellow mx-auto my-1 max-w-sm w-full font-thin"
            value="Topluluğumuza hoşgeldin. 💙"
            readOnly
          />
        </form>
      ) : (
        <form
          className="flex border-b-2 border-yellow py-4 lg:w-3/4"
          onSubmit={handleSubmit}
        >
          <label htmlFor="email" hidden>
            email
          </label>
          <input
            id="email"
            className="block bg-white outline-none focus:border-yellow mx-auto my-1 max-w-sm w-full"
            required
            onChange={handleChange}
            type="email"
            name="email"
            placeholder="Mail adresiniz zaten kayıtlı."
            value={email}
          />
          <input
            className="font-bold bg-white cursor-pointer"
            type="submit"
            value="Katıl"
          />
        </form>
      )}
    </>
  )
}

export default Subscribe
