import * as React from "react"

import Layout from "../components-tr/layout.tr"
import Seo from "../components-tr/seo"
import { StaticImage } from "gatsby-plugin-image"

const description = "tio'nun her biri hayatınızı teknolojiyle daha iyi hale getirmeyi amaçlayan dijital ürünleriyle tanışın."

const OurProducts = () => (
  <Layout navbarTheme="dark" className="bg-cream">
    <Seo title="Ürünlerimiz" description={description} canonical="https://tio.ist/tr/our-products/" />

    <div className=" relative py-10">
      <div className="container text-2xl text-center mx-auto  lg:px-10 max-w-2xl">
        <h1 className="text-5xl p-10 font-bold text-blue">Ürünlerimiz</h1>
        <p className="p-2 font-light">
          Deneyimimiz ve özgün çözüm odaklı yaklaşımımızla işletmeler için
          ürettiğimiz çözümleri yazılımlara, uygulamalara, teknoloji destekli
          araçlara dönüştürüyor ve daima geliştirmeye devam ediyoruz.
        </p>
        <p className="pt-6 px-2 font-light">Ürünlerimizin tek bir amacı var:</p>
        <p className="pb-4 px-2 font-light">
          Hayatınızı teknolojiyle daha iyi bir hale getirmek
        </p>
        <p className="pt-4 px-2 pb-2 font-light">
          Hayatın kendisinden ilham alıyoruz, gerçek çözümlerden beslenen
          dijital ürünler geliştiriyoruz.
        </p>

        <div className="mx-auto p-20 text-center ">
          <span className="p-10">
            <StaticImage
              className="bounce"
              width={30}
              src="../images/arrow.png"
              alt="Before / After tio"
            />
          </span>
        </div>
      </div>

      <div className="container text-center mx-auto  py-10 lg:px-10 ">
        <div className="mx-auto text-center ">
          <span className="p-10">
            <StaticImage
              width={150}
              src="../images/7islem-logo.png"
              alt="7işlem"
            />
          </span>
        </div>
        <div className="mx-auto text-center w-full">
          <span className="w-full">
            <StaticImage
              className="w-full"
              src="../images/7islem-banner.jpg"
              alt="7işlem"
            />
          </span>
        </div>
        <p className="text-2xl p-10 font-light max-w-xl mx-auto text-center">
          <span className="font-bold">7işlem</span> D2C firmaların e-ticaret,
          perakende satış, pazarlama, muhasebe ve üretim süreçlerini tek bir
          platformdan yöneterek verimli çalışabilmelerini sağlayan uçtan uca bir
          işletme yönetim yazılımıdır. Bir işletmenin tedarikten teslimata
          finansal ve operasyonel süreçlerini yazılım destekli hale getirmek
          için birbirine entegre olabilen işletme yönetimi, satış yönetimi ve
          ürün yönetimi ürünlerinden oluşan 7işlem sayesinde tedarikten
          teslimata tüm temel ve ileri işletme fonksiyonları tek bir platformda
          toplanır.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue underline"
          href="https://7islem.com"
        >
          www.7islem.com ↗{" "}
        </a>
      </div>

      <div className="container text-center mx-auto  py-10 lg:px-10 ">
        <div className="mx-auto text-center ">
          <span className="p-10">
            <StaticImage
              width={150}
              src="../images/hipodoc-logo.png"
              alt="Hipodoc"
            />
          </span>
        </div>
        <div className="mx-auto text-center ">
          <span className="w-full">
            <StaticImage
              className="w-full"
              src="../images-tr/hipodoc-banner.jpg"
              alt="Hipodoc"
            />
          </span>
        </div>
        <p className="text-2xl p-10 font-light max-w-xl mx-auto text-center">
         <span className="font-bold">Hipodoc</span> tüm tıp doktorları, diyetisyenler, diş hekimleri ve
          veterinerler için vaka odaklı bir kayıt ve paylaşım platformudur. Bir
          sağlık bilişimi ürünü olan Hipodoc medikal profesyonellerin vakaları
          kolayca not, fotoğraf ve videolar ile destekleyerek kaydedip
          arşivlemesini sağlar. Arşivlediğiniz vakalara tüm cihazlardan her
          zaman ulaşabilir, isterseniz vakaları diğer meslektaşlarınızla
          paylaşabilirsiniz. Diğer profesyonellerin deneyimlerine ulaşabilir,
          soru sorabilir, danışabilirsiniz.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue underline"
          href="https://hipodoc.com"
        >
          www.hipodoc.com ↗{" "}
        </a>
      </div>
      <div className="container text-center mx-auto  py-10 lg:px-10 ">
        <div className="mx-auto text-center ">
          <span className="p-10">
            <StaticImage
              width={150}
              src="../images/cember-logo.png"
              alt="Çember"
            />
          </span>
        </div>
        <div className="mx-auto text-center ">
          <span className="w-full">
            <StaticImage
              className="w-full"
              src="../images-tr/cember-banner.jpg"
              alt="Çember"
            />
          </span>
        </div>
        <p className="text-2xl p-10 font-light max-w-xl mx-auto text-center">
          Çember kullanıcıların finansmana ihtiyaç duydukları ortak bir konu
          veya miktar için çember oluşturarak birikim yapmalarını sağlayan bir
          sosyal birikim uygulamasıdır.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue underline"
          href="https://cemberapp.com"
        >
          www.cemberapp.com ↗
        </a>
      </div>
    </div>
  </Layout>
)

export default OurProducts
