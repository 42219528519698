/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "./header.tr"
import Footer from "./footer.tr"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryTr {
      site {
        siteMetadata {
          title
        }
      }
    }  
  `)

  return (
    <>

      <Header siteTitle={data.site.siteMetadata?.title || `Tio`} />
      <main>{children}</main>
      <div className="relative">
        <div className="absolute z-50 -bottom-20 right-20">
          <StaticImage src="../images/cursor.png" width={100} alt="tio" />
        </div>
        <div className="ticker-wrap ">
          <Link className="ticker z-10 " to="/tr/digital-consultancy">
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>
          </Link>
          <Link className="ticker z-10" to="/tr/digital-consultancy">
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>
            <div className="ticker-item">
              <p className="text-white" to="/tr/digital-consultancy">Ücretsiz Demo Talebi</p>
              <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
            </div>

          </Link>

        </div>
      </div>

      <Footer />


    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
