import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Subscribe from "./subscribe.tr"

const Footer = () => {
  return (
    <footer
      style={{
        marginTop: `2rem`,
      }}
    >
      <div className="flex flex-wrap w-full py-5 large:pt-20">
        <div className="block lg:w-4/12 px-20 lg:pt-20">
          <StaticImage
            src="../images/logo-blue.png"
            width={90}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="tio"
          />
          <div className="py-20">
            <Link className="w-full block py-2" to="/">
              <span className="yellow-hover">English</span>
            </Link>
            <Link className="w-full block py-2" to="/tr">
              <span className="yellow-hover font-bold">Türkçe</span>
            </Link>
          </div>
        </div>

        <div className="block lg:w-3/12 px-20 lg:pt-20">
          <div className="pb-16">
            <h4 className="text-blue font-3xl font-bold">Bize yazın</h4>
            <Link className="w-full block py-2 " to="/">
              <span className="border-b-2 border-yellow"> merhaba@tio.ist</span>
            </Link>
            <Link className="w-full block" to="/">
              +90 216 265 14 64
            </Link>
          </div>
          <div className="pb-12">
            <h4 className="text-blue font-3xl font-bold">Ofis</h4>
            <p className="w-full block py-2 ">
              <a
                href="https://goo.gl/maps/mDopNG9WA2QGSJtM8"
                rel="noreferrer"
                target="_blank"
              >
                Rasimpaşa Mah. Recaizade Sokak No:52/A Kadıköy İstanbul, Turkey
              </a>
            </p>
          </div>
        </div>

        <div className="block w-full lg:w-5/12 px-20 lg:pt-20 ">
          <div className="flex w-full">
            <div className="w-full lg:w-1/2">
              <Link className="w-full block my-2" to="/tr">
                <span className="yellow-hover">Ana Sayfa</span>
              </Link>
              <Link className="w-full block my-2 " to="/tr/our-products">
                <span className="yellow-hover">Ürünler</span>
              </Link>
              <Link className="w-full block my-2 " to="/tr/services">
                <span className="yellow-hover">Hizmetler</span>
              </Link>
              <Link className="w-full block my-2 " to="/tr/resources">
                <span className="yellow-hover">Kaynaklar</span>
              </Link>
              <Link className="w-full block my-2 " to="/tr/contact">
                <span className="yellow-hover">İletişim</span>
              </Link>
              <Link className="w-full block my-2 " to="/tr/about">
                <span className="yellow-hover">Hakkımızda</span>
              </Link>
              <Link className="w-full block my-2 " to="/tr/careers">
                <span className="yellow-hover">Kariyer</span>
              </Link>
              <Link className="w-full block my-2 " to="/tr/blog">
                <span className="yellow-hover">Blog</span>
              </Link>
            </div>
            <div className="w-full lg:w-1/2">
              <Link
                className="w-full block my-2 hover:underline"
                to="/tr/digital-consultancy"
              >
                Randevu Alın
              </Link>
              <p className="w-full block my-2 font-bold" to="/">
                Bizi takip edin
              </p>
              <a
                className="w-full block my-2 underline"
                rel="noreferrer"
                target="_blank"
                href="https://instagram.com/tio.ist"
              >
                Instagram
              </a>
              <a
                className="w-full block my-2 underline"
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/tioist/"
              >
                LinkedIn
              </a>
              <a
                className="w-full block my-2 underline"
                rel="noreferrer"
                target="_blank"
                href="https://twitter.com/tioist"
              >
                Twitter
              </a>
              <a
                className="w-full block my-2 underline"
                rel="noreferrer"
                target="_blank"
                href="https://behance.net/tioist"
              >
                Behance
              </a>
              <a
                className="w-full block my-2 underline"
                rel="noreferrer"
                target="_blank"
                href="https://medium.com/tio-ist"
              >
                Medium
              </a>
              <a
                className="w-full block my-2 underline"
                rel="noreferrer"
                target="_blank"
                href="https://kommunity.com/@tio"
              >
                Kommunity
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap w-full pt-10 pb-5 large:pb-20">
        <div className="block font-thin order-last text-blue w-full lg:w-4/12 px-20 py-10 lg:pb-10 lg:order-first">
          <p>
            <br /> © {new Date().getFullYear()}, Tio Bilişim A.Ş.
          </p>
        </div>
        <div className="block w-full lg:w-3/12 px-20 pt-10 pb-5 lg:pb-20">
          <p>Dijital ürünlerle ilgili maillerimizi almak için 👉🏻</p>
        </div>
        <div className="block w-full lg:w-5/12 px-20 pt-5 pb-10 lg:pb-20">
          <Subscribe />
        </div>
      </div>
    </footer>
  )
}

export default Footer
